:root {
  --background: #000000;
  --text: #fff;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  background-color: var(--background);
}

body {
  background-color: var(--background);
  color: var(--text);
}

@keyframes fadeInAnimation {
  0% {
      opacity: 0;
  }
  100% {
      opacity: 1;
  }
}

.splash {
  width: 100vw;
  height: 100vh;
  position: relative;
}

.splash video {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 0;
  object-fit: cover;

  animation: fadeInAnimation ease 2s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

.splash section {
  width: 100%;
  height: 100%;
  z-index: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.splash img {
  width: 100px;
  opacity: 0;

  animation: fadeInAnimation ease 2s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  animation-delay: 1s;
}

.splash p {
  font-size: 12px;
  max-width: 300px;
  text-align: center;
  margin: 30px;
  opacity: 0;

  animation: fadeInAnimation ease 2s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  animation-delay: 1.5s;
}

.splash a {
  font-weight: 800;
  font-size: 16px;
  color: #fff;
  text-decoration: none;
  opacity: 0;

  animation: fadeInAnimation ease 2s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  animation-delay: 2s;
}

.splash a:hover {
  text-decoration: underline;
}

.hero {
  width: 100%;

  animation: fadeInAnimation ease 1s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

.hero section {
  width: 100%;
  display: flex;
  position: relative;
}

.hero .title {
  padding: 50px 50px 0px 50px;
}

.hero .title img {
  width: 100%;
}

.hero section .bio {
  max-width: 300px;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 50px 50px 50px 0px;
}

.hero section .bio p {
  width: 100%;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 1.5px;
}

.hero section .socials {
  padding: 50px 50px 50px 0px;
 
  
}

.hero section .socials a {
  color: var(--text);
}

.hero section .socials a:hover {
  color: var(--text);
}

.hero section .socials svg {
   width: 30px;
}

@media (max-width: 840px) {
  .hero section {
    flex-direction: column;
  }
  .hero section .bio {
    width: 100%;
    max-width: none;
    padding: 20px;
  }
  .hero section .socials {
    padding: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .hero section .socials svg {
    margin: 10px;
  }
  
}


.portfolio {
  padding: 30px;

  animation: fadeInAnimation ease 2s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

.portfolio__labels {
  padding-bottom: 30px;
}

.portfolio__labels a{
  margin-right: 30px;
  font-size: 10px;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  transition: .2s;
  font-weight: 800;
}

.portfolio__labels a.default-label{
  background-color: transparent;
  color: #fff;
  border: 1px solid #fff;
  padding: 4px 12px;
  border-radius: 20px;
}

.portfolio__labels a.selected-label{
  background-color: #fff;
  color: #000000;
  border: 1px solid #fff;
  padding: 4px 12px;
  border-radius: 20px;
}

.portfolio__container ul {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.portfolio__container ul li {
  list-style: none;
  padding-bottom: 30px;
}

.portfolio__container ul li.card a{
  display: flex;

  text-decoration: none;
}

.portfolio__container ul li.card .card_preview {
  width: 500px;
  height: 300px;
}

.portfolio__container ul li.card .card_preview img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.portfolio__container ul li.card .card_info {
  padding-left: 30px;
  flex: 1;
}

.portfolio__container ul li.card .card_info p {
  
  text-decoration: none;
  color: var(--text);

  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 1.5px;
}

.portfolio__container ul li.card .card_info p.type {
  background-color: #fff;
  color: #000000;
  border: 1px solid #fff;
  padding: 4px 12px;
  border-radius: 20px;
  width: fit-content;
  font-size: 10px;
  font-weight: 800;
  margin-top: 10px;
}


@media (max-width: 840px) {
  .hero .title {
    padding: 20px 20px 0px 20px;
  }
  .portfolio__labels {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }
  .portfolio__labels a{
    margin: 10px;
    
  }

  .portfolio__container ul li.card a{
    display: flex;
    flex-direction: column;
  }
  
  .portfolio__container ul li.card .card_preview {
    width: 100%;
  }
  .portfolio__container ul li.card .card_info {
    padding-left: 0;
    padding-top: 10px;
  }
}

.frame .head-wrapper {
  padding: 30px 30px 0px 30px;
}

.frame .head{
  display: flex;
  align-items: center;
  

  max-width: 1080px;
  margin: 0 auto;
}

.frame .head a {
  min-width: 25px;
  color: var(--text);
  margin-right: 20px;
  
}


.frame .head h1 {
  font-size: 24px;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  color: var(--text);
}

.frame .head p {
  color: var(--text);
  max-width: 600px;

  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 1.5px;
}

.frame .video-wrapper {
  
  width: 100%;
  text-align: center;
  padding: 30px;
}

.frame .video-wrapper iframe {
  width: 100%;
  max-width: 1080px;
  margin: 0 auto;
  aspect-ratio: 16/9;
  border: 1px solid #fff;
  border-radius: 5px;
  
}


@media (max-width: 840px) {
  .frame .head a {
    min-width: 40px;
    color: var(--text);
    margin-right: 20px;
    
  }
  .frame .video-wrapper iframe {

    aspect-ratio: 1/1;

  }
}